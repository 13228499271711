import { useCallback, useEffect, useState } from 'react'
import ModalCreateEdit from '../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../baseComponents/PermissionChecker/function'
import { RowData } from '../../baseComponents/TwinTable/types'
import { FormRender } from '../../forms/FormRenderer/types'
import { twinFetchPostJSON } from '../../utils/globals/data'
import { valueOrDefaultValue } from '../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../utils/hooks/useTwinTranslation'
import { getCustomers } from '../../utils/reducers/getters'
import TableTaskWorkLogs from './TaskBagOfHours'
import { parsedDataBagOfHoursDetail } from './TaskBagOfHours/functions'
import { METaskBagOfHoursModelTypeExtended } from './TaskBagOfHours/types'
import { useParams } from 'react-router'

interface ModalEditTaskBagOfHoursProps extends ModalEditComponentProps { }

const ModalEditTaskBagOfHours: React.FC<ModalEditTaskBagOfHoursProps> = ({ allRowData, userPermissions, ...rest }) => {
    const { t } = useTwinTranslation()
    const { data, handleChangeData } = useModalEditTaskBagOfHoursLogic({ allRowData })
    const customers = getCustomers()
    const params = useParams<{ id: string }>()
    const CustomerId = parseInt(params.id || '') 
    const fields: FormRender<METaskBagOfHoursModelTypeExtended> = [
        {
            cols: 4,
            elements: [
                {
                    name: 'CustomerId',
                    label: t('customer', 'Cliente'),
                    component: 'CustomSelect',
                    required: true,
                    items: customers?.all || {},
                    value: data?.CustomerProject?.CustomerId || CustomerId,
                    readOnly: true,
                },
                {
                    name: 'CustomerProjectId',
                    label: t('project', 'Proyecto'),
                    component: 'CustomSelect',
                    required: true,
                    items: data ? { [data?.CustomerProjectId]: data?.CustomerProject } : {},
                    readOnly: true
                },
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                },
                {
                    name: 'timeLimit',
                    label: t('timeLimit', 'Tiempo límite'),
                    component: 'InputHourMinSecUnlimitedStateLess',
                    required: true,
                    value: String(data?.timeLimit || 0),
                    onFinalChange: (value) => handleChangeData('timeLimit', value)
                },
                {
                    name: 'remainingTime',
                    label: t('remainingTime', 'Tiempo restante'),
                    component: 'InputHourMinSecUnlimitedStateLess',
                    value: String(data?.remainingTime || 0),
                    onFinalChange: (value) => handleChangeData('remainingTime', value)
                },
                {
                    name: 'expireDate',
                    label: t('expireDate', 'Fecha de vencimiento'),
                    component: 'InputCalendarStateFull',
                    onlyValids: true,
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                }
            ]
        }
    ]
    if (allRowData && userPermissions) {
        fields[0].elements.push({
            name: 'active',
            label: t('active', 'Activo'),
            component: 'CheckboxMargin',
        })
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (
            <ModalCreateEdit fields={parsedFields} url={'/api/app/task/bagOfHours/updateTaskBagOfHours'} className='notFlexAutoFormRender' translations={{ title: t('editBagOfHours', 'Editar bolsa de horas'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} {...rest} >
                {data ? <TableTaskWorkLogs data={data.Tasks} /> : null}
            </ModalCreateEdit>
        )
    }
    return null
}
interface ModalEditTaskBagOfHoursLogicProps {
    allRowData?: RowData
}

const useModalEditTaskBagOfHoursLogic = ({ allRowData }: ModalEditTaskBagOfHoursLogicProps) => {
    const [data, setData] = useState<METaskBagOfHoursModelTypeExtended | null>(null)
    const getData = useCallback(async () => {
        const bagOfHours = await twinFetchPostJSON('/api/app/task/bagOfHours/getTaskBagOfHoursForDetail', { id: allRowData?.id })
        if (bagOfHours) {
            const result = parsedDataBagOfHoursDetail(bagOfHours)
            setData(result)
        }
    }, [setData, allRowData?.id])

    const handleChangeData = useCallback((field: keyof METaskBagOfHoursModelTypeExtended, value: number) => {
        setData((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            const parseTimeLimit = parseInt(String(copyOld.timeLimit))
            const parseRemainingTime = parseInt(String(copyOld.remainingTime))
            if (field === 'timeLimit') {
                if (value < parseRemainingTime) {
                    copyOld.remainingTime = value
                }
            } else if (field === 'remainingTime') {
                if(value > parseTimeLimit) {
                    copyOld.timeLimit = value
                }
            }
            return {...copyOld, [field]: value}
        })
    }, [setData])

    useEffect(() => {
        getData()
    }, [getData])

    return { data, handleChangeData }
}

export default ModalEditTaskBagOfHours