import { TaskBagOfHoursModelType } from '@teinor/erp/types/company/task/taskBagOfHours'
import { useCallback, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { ButtonOrLoader, ButtonPrimary } from '../../../../../baseComponents/Button'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import { CheckboxMargin } from '../../../../../forms/Checkbox'
import { InputHidden } from '../../../../../forms/Input'
import { InputHourMinSecUnlimitedStateFull } from '../../../../../forms/Input/InputHourMinSec/InputHourMinSecUnlimited'
import TwinForm from '../../../../../forms/TwinForm'
import { BeforeSubmitHandler } from '../../../../../forms/TwinForm/types'
import { twinFetchPostJSON } from '../../../../../utils/globals/data'
import { displayFormatedHourFromSecs } from '../../../../../utils/globals/date'
import withLoading from '../../../../../utils/hoc/withLoading'
import useIsLoading from '../../../../../utils/hooks/useIsLoading'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../utils/reducers'
import { CustomerPayload } from '../../../../../utils/reducers/customers/customer'
import { getBottomNavApp } from '../../../../../utils/reducers/getters'
import { addBottomNavAppElement, changeErrorMessage } from '../../../../../utils/reducers/reduxDispatch'
import { TaskExtendedTy } from '../../types'
import { ModalTaskInvertedTimeBaseProps } from '../types'

type ModalTaskAddTimeProps = ModalTaskAddTimeLogicProps & ReduxModalTaskAddTime & { }

const ModalTaskAddTime: React.FC<ModalTaskAddTimeProps> = ({ setOpenModalInvertedTime, data }) => {
    const { t } = useTwinTranslation()

    const { selectedBagOfHours, bagOfHours, handleSelectBagOfHours, startToWork, isWorking, loading, endLoading, handleBeforeSubmit } = useModalTaskAddTimeLogic({ data, setOpenModalInvertedTime })
    const renderThis: JSX.Element[] = []
    if (data?.CustomerProject && bagOfHours?.length){
        for (const bagOfHour of bagOfHours) {
            const myBagOfHour: any = bagOfHour
            const parsedLimitTime = displayFormatedHourFromSecs(bagOfHour.timeLimit)
            const parsedConsumedTime = displayFormatedHourFromSecs(bagOfHour.consumedTime)
            const parsedRemainingTime = displayFormatedHourFromSecs(bagOfHour.remainingTime)
            const parsedName = myBagOfHour?.name + ' - ' + parsedConsumedTime + '/' + parsedLimitTime + ' (' + t('remain', 'Quedan') + ' ' + parsedRemainingTime + ')'
            renderThis.push(
                <CheckboxMargin key={myBagOfHour.id} checked={selectedBagOfHours === bagOfHour.id} className={bagOfHour.remainingTime <= 0 ? 'text-red-BA' : ''} onChange={() => handleSelectBagOfHours(bagOfHour.id)} label={parsedName} />
            )
        }
    }
    return (
        <TwinForm action='/api/app/task/workLog/createTaskWorkLog' beforeSubmitHandler={handleBeforeSubmit} onSubmit={() => setOpenModalInvertedTime(null)} onError={endLoading} className='flex-auto flex flex-col h-1'>
            <h2>
                <TwinTrans transKey='workIn'>Trabajar en</TwinTrans>
            </h2>
            <div className='mt-10'>
                <TwinTrans transKey='selectBagOfHoursOrNotToWork'>
                    Selecciona una de las bolsas de horas del proyecto asignado, o puedes no seleccionar ninguna para trabajar en la tarea únicamente.
                </TwinTrans>
            </div>
            <div className='my-20'>
                {renderThis}
            </div>
            <div className='mt-10'>
                <TwinTrans transKey='canIntroduceHours'>
                    Puedes introducir manualmente las horas que has invertido en la tarea:
                </TwinTrans>
            </div>
            <InputHidden name='startDate' value={new Date().toISOString()} />
            <InputHidden name='endDate' value={new Date().toISOString()} />
            <InputHidden name='TaskBagOfHoursId' value={selectedBagOfHours||undefined} />
            <InputHidden name='TaskId' value={data?.id} />
            <div className='flex items-end'>
                <div className='flex flex-auto'>
                    <InputHourMinSecUnlimitedStateFull className='w-auto' label='' name='workedSecs' />
                </div>
                <ButtonOrLoader className='ml-20' buttonIsDisabled={loading} textButton={t('addTime', 'Añadir tiempo')} />
            </div>
            <div className='mt-30'>
                <TwinTrans transKey='orYouCanStartChrono'>
                    O bien puedes empezar un temporizador para controlar el tiempo que inviertes en la tarea 
                </TwinTrans>
            </div>
            <div className='mt-30'>
                <ButtonPrimary onClick={startToWork} type='button' disabled={isWorking}>
                    <TwinTrans transKey='startWorking'>
                        Empezar a trabajar
                    </TwinTrans>
                </ButtonPrimary>
            </div>
        </TwinForm>
    )
}


interface ModalTaskAddTimeLogicProps extends ModalTaskInvertedTimeBaseProps  {
    data: TaskExtendedTy
}

const useModalTaskAddTimeLogic = ({ data, setOpenModalInvertedTime }: ModalTaskAddTimeLogicProps) => {
    const [bagOfHours, setBagOfHours] = useState<TaskBagOfHoursModelType[] | null>(null)
    const [selectedBagOfHours, setSelectedBagOfHours] = useState<number | null>(null)
    const {t} = useTwinTranslation()
    const bottomNavApp = getBottomNavApp()
    const isWorking = bottomNavApp['workingModal'] ? true : false
    
    const errorAddTime = t('mustAddTime', 'Debe añadir tiempo')
    const {  endLoading, loading } = useIsLoading()

    const handleSelectBagOfHours = useCallback((id: number) => {
        setSelectedBagOfHours((old) => {
            if (old === id) {
                return null
            }
            return id
        })
    }, [setSelectedBagOfHours])
    
    const handleBeforeSubmit: BeforeSubmitHandler = useCallback((values: any) => {
        if(parseInt(String(values.workedSecs)) === 0){
            changeErrorMessage(errorAddTime)
            return false
        }
        return true
    }, [errorAddTime])

    const getBagOfHours = useCallback(async(id: number) => {

        const result = await twinFetchPostJSON('/api/app/task/bagOfHours/getAllTaskBagsOfHours', { where: { 'CustomerProjectId': id, 'TaskBagOfHours.active': true }, order: [['id' ,'ASC']] })
        if(result){
            setBagOfHours(result)
        }
    }, [setBagOfHours])

    const startToWork = useCallback(async () => {
        const now = new Date()
        const result = await twinFetchPostJSON('/api/app/task/workLog/createTaskWorkLog', { TaskId: String( data?.id), startDate: now.toISOString(), TaskBagOfHoursId: selectedBagOfHours })
        if (result) {
            addBottomNavAppElement({ type: 'workingModal', key: 'workingModal', extraData: { id: result.id, name: data?.name, TaskId: String(data?.id), startDate: now.toISOString(), TaskBagOfHoursId: selectedBagOfHours  } })
            setOpenModalInvertedTime(null)
        }
    }, [data?.id, data?.name, setOpenModalInvertedTime, selectedBagOfHours])


    useEffect(() => {
        if(data?.CustomerProject){
            getBagOfHours(data?.CustomerProject.id)
        }
    }, [data?.CustomerProject, getBagOfHours])

    return { selectedBagOfHours, handleSelectBagOfHours, startToWork, isWorking, bagOfHours, endLoading, loading, handleBeforeSubmit }
}

const modalTaskAddTimeDispatch = {
    setCustomers: (payload: CustomerPayload) => ({ type: 'CHANGE_CUSTOMER', payload }),
}

type ReduxModalTaskAddTime = ConnectedProps<typeof modalTaskAddTimeConnect>
const mapModalTaskAddTimeConnector = (state: AllReduxPayloads) => ({ customers: state.customers })
const modalTaskAddTimeConnect = connect(mapModalTaskAddTimeConnector, modalTaskAddTimeDispatch)
const taskConnectLoading = withLoading(ModalTaskAddTime, [{ fetchUrl: '/api/app/customer/getAllCustomersComplete', propName: 'customers', setFunctionName: 'setCustomers' }])
const ModalTaskAddTimeConnect = modalTaskAddTimeConnect(taskConnectLoading)

export default ModalTaskAddTimeConnect