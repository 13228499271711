import { faHouse, faPhone, faMobile, faEnvelope, faCopy } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useMemo } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import TwinIcon, { TwinIconProp } from '../../../../baseComponents/TwinIcon'
import TwinToolTip from '../../../../baseComponents/TwinToolTip'
import { CustomSelectProps, CustomSelectWithSearchBar } from '../../../../forms/CustomSelect'
import { TwinDictionary } from '../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { changeSuccessMessage } from '../../../../utils/reducers/reduxDispatch'


interface CustomSelectTaskContactInfoProps extends CustomSelectProps {
    id: string
    selectedContact?: TwinDictionary
}

const CustomSelectTaskContactInfo: React.FC<CustomSelectTaskContactInfoProps> = ({ id, selectedContact, ...rest }) => {
    return (
        <div>
            {selectedContact ? <TwinToolTip place='top' anchorSelect={'#contact' + id} clickable>
                <div>
                    <div>{selectedContact?.name}</div>
                    <CustomSelectTaskContactInfoRowClipboard icon={faHouse} text={selectedContact?.address} />
                    <CustomSelectTaskContactInfoRowClipboard icon={faPhone} text={selectedContact?.phone} />
                    <CustomSelectTaskContactInfoRowClipboard icon={faMobile} text={selectedContact?.mobile} />
                    <CustomSelectTaskContactInfoRowClipboard icon={faEnvelope} text={selectedContact?.email} />
                </div>
            </TwinToolTip>
                : null}
            <div id={'contact' + id}>
                <CustomSelectWithSearchBar {...rest} sortItems={true} />
            </div>
        </div>
    )
}

interface CustomSelectTaskContactInfoRowClipboardProps {
    text?: string
    icon: TwinIconProp
}

export const CustomSelectTaskContactInfoRowClipboard: React.FC<CustomSelectTaskContactInfoRowClipboardProps> = ({ text, icon }) => {
    const { handleCopy } = CustomSelectTaskContactInfoRowClipboardLogic()
    if (!text) {
        return null
    }
    return (
        <div className='flex items-center'>
            <div className='mr-10 w-16'>
                <TwinIcon icon={icon} />
            </div>
            <span className='mr-10'>
                {text}
            </span>
            {text?.length ?
                <CopyToClipboard text={text || ''} onCopy={handleCopy}>
                    <TwinIcon className='ml-auto cursor-pointer hover:text-green-43' icon={faCopy} />
                </CopyToClipboard>
                : null
            }
        </div>
    )
}

interface CustomSelectTaskContactInfoRowProps {
    text?: string
    icon: TwinIconProp
}

export const CustomSelectTaskContactInfoRow: React.FC<CustomSelectTaskContactInfoRowProps> = ({ text, icon }) => {
    if (!text) {
        return null
    }
    return (
        <div className='flex items-center'>
            <div className='mr-10 w-16'>
                <TwinIcon icon={icon} />
            </div>
            <span className='mr-10'>
                {text}
            </span>
        </div>
    )
}


const CustomSelectTaskContactInfoRowClipboardLogic = () => {
    const { t } = useTwinTranslation()
    const correctlyCopied = t('copiedCorrectly', 'Copiado correctamente')
    const handleCopy = useCallback(() => {
        changeSuccessMessage(correctlyCopied)
    }, [correctlyCopied])
    return { handleCopy }
}
interface CustomSelectTaskContactNotesProps {
    notes?: string
    icon: TwinIconProp
}

export const CustomSelectTaskContactNotes: React.FC<CustomSelectTaskContactNotesProps> = ({ notes, icon }) => {
    const textMemo = useMemo(() => {
        if (!notes) {
            return null
        }
        const separatedNotes = notes.split('\n')
        const textNotes: string[] = []
        for (const note of separatedNotes) {
            textNotes.push(note.replace(/<[^>]*>/g, ''))
        }
        return textNotes
    }, [notes])

    if (!textMemo) {
        return null
    }

    const renderThis: JSX.Element[] = []

    for (const key in textMemo) {
        const element = textMemo[key]
        renderThis.push(<div key={key}>{element}</div>)
    }

    return (
        <div className='flex items-end'>
            <div className='mr-10 w-16 self-start'>
                <TwinIcon icon={icon} />
            </div>
            <p className='mr-10 max-w-300'>
                {renderThis}
            </p>
        </div>
    )
}

export default CustomSelectTaskContactInfo