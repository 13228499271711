import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { TaskModelType } from '@teinor/erp/types/company/task'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import { CustomRenderDateHours } from '../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { getCustomers } from '../../../../utils/reducers/getters'
import { CustomRenderTaskName } from '../../TaskScreenTable/Subcomponents'
import TaskTable from '../../TaskScreenTable/TaskTable'
import { useTaskScreenTableLogic } from '../../TaskScreenTable'

interface SectionCustomerTasksProps {
    CustomerId: string
}

export const SectionCustomerTasks: React.FC<SectionCustomerTasksProps> = ({ CustomerId }) => {
    const { t } = useTwinTranslation()
    const name = 'tableCustomerTasks'
    const customers = getCustomers()
    const customer = customers?.all?.[parseInt(CustomerId)]?.name

    const columns: ColumnTableSchema<TaskModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('nº', 'Nº'),
            customRender: (parameterValue) => <div className='text-green-43'>#{parameterValue}</div>,
            sortable: true,
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            minWidth: 120,
            hideColumnByDefault: true,
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskName id={String(allRowData?.id)} name={allRowData?.name} />,
        },
        {
            id: 'createdAt',
            dataKey: 'createdAt',
            label: t('createdAt', 'Fecha de creación'),
            customRender: (parameterValue) => <CustomRenderDateHours value={parameterValue} />,
        },
        {
            id: 'openTask',
            dataKey: 'id',
            label: '',
            width: 30,
            customRender: (parameterValue) => <a href={'/task/tasks/' + parameterValue} target='_blank' rel='noreferrer'><TwinIcon icon={faChevronRight} className='h-20 w-20 text-gray-B2' /></a>,
        },
    ]

    const { setSelectedColsFiltersHeader, selectedColsFiltersHeader } = useTaskScreenTableLogic({ name, limitPath: '', floatingTasks: true, columns })

    const headerTableWhereParams = {
        generalSearchBar: '',
        start_date: '',
        end_date: '',
        finished: 1,
        assigned: 1,
        visibles: 2
    }
    return (
        <div className='flex flex-col flex-auto taskDoubleTable pr-30'>
            <h2><TwinTrans transKey='taskOf'>Tareas de </TwinTrans><span>{customer}</span></h2>
            <TaskTable name={name} createRowDataLink={['/task/tasks', ['$id']]} defaultOrder={{ defaultOrderField: 'createdAt', order: 'desc' }} setSelectedColsFiltersHeader={setSelectedColsFiltersHeader} memorizedColumns={columns} selectedColsFiltersHeader={selectedColsFiltersHeader} generalSearchbar='' columns={columns} getDataFrom='/api/app/task/getAllTasks' extraWhereParams={{ 'Task.CustomerId': CustomerId }} listenSocketStr={'getAllTasksCustomer_' + CustomerId} headerTableWhereParams={headerTableWhereParams} floatingTasks={false} simpleDivContainer={true} />
        </div>)
}

export default SectionCustomerTasks