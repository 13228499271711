import { faLock, faChevronUp, faChevronDown, faCheckCircle, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle as faCheckCircleSolid } from '@fortawesome/pro-solid-svg-icons'
import { TaskWorkLogModelType } from '@teinor/erp/types/company/task/taskWorkLog'
import { useState, useCallback } from 'react'
import TwinIcon, { TwinIconProp } from '../../../baseComponents/TwinIcon'
import { CustomRenderDisplayHoursMinsSecsBySecs, CustomRenderDateHours } from '../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import TwinTrans from '../../../baseComponents/TwinTrans'
import { MEBOHTaskParsed, MEBOHEmployeeDataWithLogs } from './types'
import './tabletaskworklogs.sass'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { ColumnTableSchema } from '../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import TableWithoutVirtualListing from '../../../baseComponents/TwinTable/TableWithoutVirtualListing'
import { TableWithoutVirtualListingRowRenderer, TableWithoutVirtualListingRowProps } from '../../../baseComponents/TwinTable/TableWithoutVirtualListing/types'

interface TableTaskWorkLogsProps {
    data: MEBOHTaskParsed[]
}

const TableTaskWorkLogs: React.FC<TableTaskWorkLogsProps> = ({ data }) => {
    const { t } = useTwinTranslation()
    const memorizedRowRenderer: TableWithoutVirtualListingRowRenderer = useCallback((props) => {
        return <TaskWorkLog props={props} key={props.rowIndex} />
    }, [])

    const columns: ColumnTableSchema<MEBOHTaskParsed> = [
        {
            id: 'check',
            dataKey: 'finished',
            label: '',
            width: 45
        },
        {
            id: 'code',
            dataKey: 'id',
            label: t('code', 'Código'),
            width: 100
        },
        {
            id: 'task',
            dataKey: 'name',
            label: t('task', 'Tarea'),
            minWidth: 300
        },
        {
            id: 'hours',
            dataKey: 'workedSeconds',
            label: t('hours', 'Horas'),
            width: 150
        },
    ]

    return (
        <div className='flex flex-col flex-auto overflow-x-hidden'>
            <h2 className='title_taskworklogs'>
                <TwinTrans transKey='taskLog'>Registro de tareas</TwinTrans>
            </h2>
            <div className='h-1 w-full flex flex-col flex-auto'>
                {data.length === 0 ?
                    <TwinTrans transKey={'thereIsNoTaskLogs'}>No hay registro de tareas</TwinTrans> :
                    <TableWithoutVirtualListing data={data} columns={columns} rowRenderer={memorizedRowRenderer} />
                }
            </div>
        </div>
    )
}

interface TaskWorkLogProps {
    props: TableWithoutVirtualListingRowProps
}

const TaskWorkLog: React.FC<TaskWorkLogProps> = ({ props }) => {
    let { id, name, finished, workedSeconds } = props.rowData
    const dictWidthColumns = props.dictWidthColumns
    const { showMoreTaskLogInfo, handleShowMoreTaskLogInfo } = useTaskWorkLogLogic()

    return (
        <div className='flex flex-col'>
            <div className={`task_work_log_row flex cursor-pointer ${showMoreTaskLogInfo ? 'border-none' : 'border-b border-gray-EE'} items-center w-full`} onClick={handleShowMoreTaskLogInfo}>
                <div className='row_check' style={dictWidthColumns['check']}><CustomRenderBooleanFinished value={String(finished) === 'true'} width={45} /></div>
                <div className='row_code px-8' style={dictWidthColumns['code']}>#{id}</div>
                <div className='row_task px-8' style={dictWidthColumns['task']}>{name}</div>
                <div className='row_hours px-8' style={dictWidthColumns['hours']}>
                    <CustomRenderDisplayHoursMinsSecsBySecs value={workedSeconds} />
                    <div className='row_arrow text-end'><CustomDropdownIcon opened={showMoreTaskLogInfo} /></div>
                    <a href={`/task/tasks/${id}`} target='_blank' rel="noreferrer" className='ml-20'><TwinIcon icon={faChevronRight}/></a>
                </div>
            </div>
            {showMoreTaskLogInfo ? <TaskWorkLogInner props={props} /> : null}
        </div >
    )
}

const useTaskWorkLogLogic = () => {
    const [showMoreTaskLogInfo, setShowMoreTaskLogInfo] = useState<boolean>(false)

    const handleShowMoreTaskLogInfo = useCallback(() => {
        setShowMoreTaskLogInfo((old) => !old)
    }, [setShowMoreTaskLogInfo])

    return { showMoreTaskLogInfo, handleShowMoreTaskLogInfo }
}

interface TaskWorkLogInnerProps extends TaskWorkLogProps { }

const TaskWorkLogInner: React.FC<TaskWorkLogInnerProps> = ({ props }) => {
    let { dictionaryWorkLogs } = props.rowData

    const renderThis: JSX.Element[] = []
    if (dictionaryWorkLogs) {
        for (const employeeId in dictionaryWorkLogs) {
            const employeeDataWithLogs = dictionaryWorkLogs[employeeId]
            renderThis.push(
                <EmployeeInfoTask employeeDataWithLogs={employeeDataWithLogs} key={props.rowData.id + employeeId} props={props} />
            )
        }
    }
    return (<div className='flex'>{renderThis}</div>)
}


interface EmployeeInfoTaskProps extends TaskWorkLogProps {
    employeeDataWithLogs: MEBOHEmployeeDataWithLogs
}

const EmployeeInfoTask: React.FC<EmployeeInfoTaskProps> = ({ employeeDataWithLogs, props }) => {
    const { showMoreTaskLogInfo, handleShowMoreTaskLogInfo } = useTaskWorkLogLogic()
    const dictWidthColumns = props.dictWidthColumns

    const { employeeName, totalWorkedSecs } = employeeDataWithLogs
    return (
        <div className='table_background_color'>
            <div className={`employee_task flex flex-auto cursor-pointer items-center`} onClick={handleShowMoreTaskLogInfo}>
                <div className='row_check' style={dictWidthColumns['check']}></div>
                <div className='row_employee_logs flex items-center flex-auto field_padding'>
                    <img src={'/user-png.png'} className='image_employee rounded-full w-full h-full' alt='' />
                    <div className='pl-15'>{employeeName}</div>
                </div>
                <div className='row_hours' style={dictWidthColumns['hours']}>
                    <CustomRenderDisplayHoursMinsSecsBySecs value={totalWorkedSecs} />
                    <div className='row_arrow text-end'><CustomDropdownIcon opened={showMoreTaskLogInfo} /></div>
                </div>
            </div>
            {showMoreTaskLogInfo ? <EmployeeInfoTaskInner employeeDataWithLogs={employeeDataWithLogs} props={props} /> : null}
        </div>
    )
}

interface EmployeeInfoTaskInnerProps extends TaskWorkLogProps {
    employeeDataWithLogs: MEBOHEmployeeDataWithLogs
}

const EmployeeInfoTaskInner: React.FC<EmployeeInfoTaskInnerProps> = ({ employeeDataWithLogs, props }) => {
    const renderThis: JSX.Element[] = []
    if (employeeDataWithLogs) {
        for (const workLog of employeeDataWithLogs.logs) {
            renderThis.push(
                <DatesTaskWorkLog workLog={workLog} key={workLog.id} props={props} />
            )
        }
    }
    return (<div>{renderThis}</div>)
}


interface DatesTaskWorkLogProps extends TaskWorkLogProps {
    workLog: TaskWorkLogModelType
}

const DatesTaskWorkLog: React.FC<DatesTaskWorkLogProps> = ({ workLog, props }) => {
    const dictWidthColumns = props.dictWidthColumns
    return (
        <div className={`employee_work_logs flex flex-auto items-center`}>
            <div className='row_check' style={dictWidthColumns['check']}></div>
            <div className='row_employee_logs flex flex-auto field_padding'>
                <div className=''><CustomRenderDateHours value={workLog.startDate} /></div>
                <div className='px-25'>-</div>
                <div className=''><CustomRenderDateHours value={workLog.endDate || ""} /></div>
            </div>
            <div className='row_hours' style={dictWidthColumns['hours']}><CustomRenderDisplayHoursMinsSecsBySecs value={workLog.workedSecs} /></div>
        </div>
    )
}
interface CustomDropdownIconProps {
    opened: boolean
    readOnly?: boolean
    icon?: TwinIconProp
}

const CustomDropdownIcon: React.FC<CustomDropdownIconProps> = ({ icon, opened, readOnly }) => {
    let myIcon = faLock
    if (!readOnly) {
        myIcon = opened ? faChevronUp : faChevronDown
    }
    return (<TwinIcon icon={icon || myIcon} className='ml-auto mr-10' />)
}

interface CustomRenderBooleanFinishedProps {
    value: boolean
    width?: number
    onClick?: () => void
}

const CustomRenderBooleanFinished: React.FC<CustomRenderBooleanFinishedProps> = ({ value, width, onClick }) => {
    let renderThis = <TwinIcon icon={faCheckCircle} className='icon_not_finished' size='lg' />
    if (value) {
        renderThis = <TwinIcon icon={faCheckCircleSolid} className='icon_finished' size='lg' />
    }
    return (
        <div className='flex items-center w-full custom_render_boolean' onClick={onClick}>
            <div className='text-center' style={{ width: width ?? '100%' }}>
                {renderThis}
            </div>
        </div>
    )
}

export default TableTaskWorkLogs